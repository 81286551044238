import React from 'react'
import Image from 'next/image'

import { CLIENTS_LOGOS } from '../../constants/appImages'

function Clients() {
    return (
        <section>
            {/* Desltop */}
            <div className="lg:mt-16 xl:mt-20 w-full py-4 bg-slate-100 hidden lg:block">
                <div className="lg:max-w-7xl mx-auto grid gap-10 grid-cols-4 px-6 place-items-center">
                    <Image
                        src={CLIENTS_LOGOS.richCommerce}
                        alt="rich-commerce"
                        width={130}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                    <Image
                        src={CLIENTS_LOGOS.noYelling}
                        alt="no-yelling"
                        width={200}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                    <Image
                        src={CLIENTS_LOGOS.instanConsult}
                        alt="instan-consult"
                        width={200}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                    <Image
                        src={CLIENTS_LOGOS.brandWatch}
                        alt="Brandwatch"
                        width={200}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                </div>
            </div>

            {/* mobile  */}
            <div className="mt-10 w-full py-2 bg-slate-100 block lg:hidden">
                <div className="px-6 grid grid-rows-2 grid-cols-2 place-items-center gap-8 mx-auto max-w-xs sm:max-w-lg md:max-w-2xl">
                    <Image
                        src={CLIENTS_LOGOS.richCommerce}
                        alt="rich-commerce"
                        width={90}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                    <Image
                        src={CLIENTS_LOGOS.noYelling}
                        alt="no-yelling"
                        width={150}
                        className="aspect-[3/2] object-contain"
                    />
                    <Image
                        src={CLIENTS_LOGOS.instanConsult}
                        alt="instan-consult"
                        width={150}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                    <Image
                        src={CLIENTS_LOGOS.brandWatch}
                        alt="Brandwatch"
                        width={150}
                        height={100}
                        className="aspect-[3/2] object-contain"
                    />
                </div>
            </div>
        </section>
    )
}

export default Clients
